import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { indexedDbKeys } from './modules/polly-manage/@core/datasets/manage-indexed-db-keys.dataset';
import { RouterModule } from '@angular/router';
import { PollyCommonsModule } from 'polly-commons';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageService } from 'primeng/api';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    PollyCommonsModule.forRoot(),
  ],
  providers: [
    MessageService,
    { provide: 'appName', useValue: 'manage' },
    { provide: 'environment', useValue: environment },
    { provide: 'sentryUrl', useValue: indexedDbKeys.sentryUrl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
