import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import { getAuthConfig } from 'polly-commons';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// Configuration of Amplify for authentication related operations - Sign in/out(Global - true/false), Reset Password, Forget Password, MFA
Amplify.configure({...getAuthConfig(environment.cognitoUserPool, environment.cognitoClientId)});
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ domain: window.location.hostname, secure: true }));
