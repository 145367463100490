import { Component, OnDestroy } from '@angular/core';
import { WebSocketService } from 'polly-commons';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy{
  public title: string = 'polly-manage';

  constructor(private readonly websocketService: WebSocketService) {}

  public ngOnDestroy(): void {
    this.websocketService.closeConnection();
  }
}
