import {
  EnvironmentConfiguration,
  environmentTest,
} from 'polly-commons';

export const environment: EnvironmentConfiguration = {
  ...environmentTest,
  staticAssets: 'static/manage/',
};

export const dashboardListBaseUrl: string = 'https://test-dashboards.elucidata.io';
