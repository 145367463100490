import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { OptInPreloadStrategy } from 'polly-commons';

const routes: Routes = [
  {
    path: 'manage',
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/polly-manage/polly-manage.module').then((m) => m.PollyManageModule),
      },
    ],
  },
  { path: 'data-lake', redirectTo: 'manage/discover' },
  { path: 'data-lake/dashboard', redirectTo: 'manage/discover' },
  { path: 'user-project', redirectTo: 'manage/workspaces' },
  { path: 'main', redirectTo: 'manage/applications' },
  { path: '**', redirectTo: 'manage' },
  { path: '', redirectTo: 'manage', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: OptInPreloadStrategy })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
